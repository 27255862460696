import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import Collapse from '@material-ui/core/Collapse';
import IconButton from '@material-ui/core/IconButton';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Typography from '@material-ui/core/Typography';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import { PriorityHigh } from '@material-ui/icons';
import ConnectButton from '../VMConnectButton';
import DeferButton from '../VMDeferButton';
import RejectButton from '../VMRejectButton';
import { DateTime } from 'luxon';
import { RequestPagination } from './RequestPagination';
// ----------------------------------------------------------------------------

const capitalizeFirstLetter = (str) => {
  return str.charAt(0).toUpperCase() + str.slice(1);
};
// ----------------------------------------------------------------------------

const useEndpointStatusStyle = (endpointStatus) => {
  switch (endpointStatus) {
    case 'offline':
      return <span style={{ color: 'red' }}>{capitalizeFirstLetter(endpointStatus)}</span>;
    case 'online':
      return <span style={{ color: 'green' }}>{capitalizeFirstLetter(endpointStatus)}</span>;
    case 'busy':
      return <span style={{ color: 'orange' }}>{capitalizeFirstLetter(endpointStatus)}</span>;
    default:
      break;
  }
};

// ----------------------------------------------------------------------------
const useRowStyles = makeStyles({
  root: {
    '& > *': {
      borderBottom: 'unset',
    },
  },
  header: {
    fontWeight: '600',
  },
  actionColumn: {
    minWidth: '150px',
  },
});
// ----------------------------------------------------------------------------
function Row(props) {
  const { row, connect, activeTab, deferACall, rejectACall, code } = props;
  const [open, setOpen] = React.useState(false);
  const classes = useRowStyles();

  return (
    <React.Fragment>
      <TableRow className={classes.root}>
        <TableCell>
          <IconButton aria-label="expand row" size="small" onClick={() => setOpen(!open)}>
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
        <TableCell component="th" scope="row">
          {row.fin}
        </TableCell>
        <TableCell>{row.firstName + ', ' + row.lastName}</TableCell>
        <TableCell>{row.facilityName}</TableCell>
        <TableCell>{row.location}</TableCell>
        <TableCell>{row.endpointSerialNumber}</TableCell>
        <TableCell>{useEndpointStatusStyle(row.endpointStatus)}</TableCell>
        <TableCell>{row.orderType}</TableCell>
        <TableCell>{row.orderStatus}</TableCell>
        <TableCell>
          {(row.provider?.firstName || '') + ' ' + (row.provider?.lastName || '')}
        </TableCell>
        <TableCell>{row.specialityName}</TableCell>
        <TableCell>{row.isUrgent ? <PriorityHigh color="error" /> : <></>}</TableCell>
        <TableCell>
          {DateTime.fromISO(row.orderTimestamp + 'Z').toLocaleString(DateTime.DATETIME_SHORT)}
        </TableCell>
        {activeTab === 0 && (
          <TableCell className={classes.actionColumn}>
            {/* <Button text={'Connect'} disabled={row.endpointStatus !== 'offline' ? true : false} clickEvent={() => connect(row)} /> */}
            {/* TODO: modify the logic here */}
            {row.orderStatus !== 'created' && (
              // <IconButton disabled={(row.endpointStatus === 'offline') ? true : false} onClick={() => {connect(row)}}>
              //   <CallRounded color={(row.endpointStatus === 'offline') ? "inherit" : "secondary"} />
              // </IconButton>
              <ConnectButton row={row} connectACall={connect} code={code} />
            )}
            {row.orderType === 'TeleKart' && row.orderStatus !== 'created' && (
              <>
                <RejectButton row={row} rejectACall={rejectACall} />
                <DeferButton
                  row={row}
                  deferACall={deferACall}
                  disabled={row.orderStatus === 'deferred'}
                />
              </>
            )}
          </TableCell>
        )}
      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={14}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box margin={1}>
              <Typography variant="h6" gutterBottom component="div">
                Details
              </Typography>
              <Table size="small" aria-label="purchases">
                <TableHead>
                  <TableRow>
                    <TableCell className={classes.header}>MRN</TableCell>
                    <TableCell className={classes.header}>Gender</TableCell>
                    <TableCell className={classes.header}>Date of Birth</TableCell>
                    <TableCell className={classes.header}>Preferred Language</TableCell>
                    <TableCell className={classes.header}>Reason for Consult</TableCell>
                    <TableCell className={classes.header}>Special Instructions</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  <TableRow>
                    <TableCell component="th" scope="row">
                      {row.mrn}
                    </TableCell>
                    <TableCell>{row.sex}</TableCell>
                    <TableCell>
                      {DateTime.fromISO(row.dob + 'Z').toLocaleString(DateTime.DATE_SHORT)}
                    </TableCell>
                    <TableCell>{row.preferredLanguage}</TableCell>
                    <TableCell>{row.reason}</TableCell>
                    <TableCell>{row.specialInstructions}</TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </React.Fragment>
  );
}
// ----------------------------------------------------------------------------

function EmptyRow({ activeTab }) {
  const classes = useRowStyles();

  return (
    <React.Fragment>
      <TableRow className={classes.root}>
        <TableCell colSpan={14} align="center">
          {activeTab === 0
            ? 'No request has been assigned to me.'
            : 'No request found. Please reset filter.'}
        </TableCell>
      </TableRow>
    </React.Fragment>
  );
}
// ----------------------------------------------------------------------------
export const RequestTable = (props) => {
  const {
    activeTab,
    requests,
    facility,
    specialty,
    type,
    size,
    page,
    connect,
    npi,
    deferACall,
    rejectACall,
    code,
    status,
    handlePageChange,
  } = props;
  const headerStyle = { color: '#03ADD0' };

  const filterResult = (row, index) => {
    switch (activeTab) {
      case 1:
        return (
          (row.orderStatus === status || status === '') &&
          (row.facilityId.toString() === facility || facility === '') &&
          (row.specialityId.toString() === specialty || specialty === '') &&
          (row.orderType === type || type === '')
        );
      default:
        //TODO: api should return providerId, change filter condition
        return (
          row.provider?.npiNumber === npi &&
          row.orderStatus !== 'completed' &&
          row.orderStatus !== 'notaccepted' &&
          row.orderStatus !== 'canceled'
        );
      // return row.orderStatus !== 'completed';
    }
  };

  const filterPageResult = (index) => {
    return index < size * page && index >= size * (page - 1);
  };
  return (
    <Table aria-label="collapsible table">
      <TableHead>
        <TableRow>
          <TableCell />
          <TableCell style={headerStyle}>FIN</TableCell>
          <TableCell style={headerStyle}>Patient Name</TableCell>
          <TableCell style={headerStyle}>Facility</TableCell>
          <TableCell style={headerStyle}>Location</TableCell>
          <TableCell style={headerStyle}>Device Name</TableCell>
          <TableCell style={headerStyle}>Device Status</TableCell>
          <TableCell style={headerStyle}>Type</TableCell>
          <TableCell style={headerStyle}>Status</TableCell>
          <TableCell style={headerStyle}>Assigned To</TableCell>
          <TableCell style={headerStyle}>Speciality</TableCell>
          <TableCell style={headerStyle}>Urgent</TableCell>
          <TableCell style={headerStyle}>Time</TableCell>
          {activeTab === 0 && <TableCell style={headerStyle}>Action</TableCell>}
        </TableRow>
      </TableHead>
      <TableBody>
        {requests &&
          requests
            .filter((row, index) => {
              return filterResult(row, index);
            })
            .filter((row, index) => {
              return filterPageResult(index);
            })
            .map((row, index) => (
              <Row
                key={row.mrn + index}
                row={row}
                connect={connect}
                activeTab={activeTab}
                deferACall={deferACall}
                rejectACall={rejectACall}
                code={code}
              />
            ))}
        {requests &&
          requests
            .filter((row, index) => {
              return filterResult(row, index);
            })
            .filter((row, index) => {
              return filterPageResult(index);
            }).length === 0 && <EmptyRow activeTab={activeTab} />}
        <TableRow>
          <TableCell colSpan={14} align="right">
            <RequestPagination
              requests={requests}
              page={page}
              handlePageChange={handlePageChange}
              filterResult={filterResult}
            />
          </TableCell>
        </TableRow>
      </TableBody>
    </Table>
  );
};

import React from 'react';
import { Table, TableCell, TableHead, TableRow, TableBody } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import AppointmentRow from './AppointmentRow';

const useStyles = makeStyles(() => ({
  header: {
    color: '#03ADD0',
  },
}));

export const OutpatientAppointmentTable = (props) => {
  const { outpatientAppointments } = props;
  const classes = useStyles();
  return (
    <Table aria-label="table">
      <TableHead>
        <TableRow>
          <TableCell className={classes.header}>MRN</TableCell>
          <TableCell className={classes.header}>Date</TableCell>
          <TableCell className={classes.header}>Time</TableCell>
          <TableCell className={classes.header}>Patient</TableCell>
          <TableCell className={classes.header}>Status</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {outpatientAppointments &&
          outpatientAppointments.data.map((appointment) => (
            <AppointmentRow key={appointment.id} row={appointment} />
          ))}
      </TableBody>
    </Table>
  );
};

import React from 'react';
import { PriorityHigh } from '@material-ui/icons';

const ErrorMessage = (props) => {
  const { header, body } = props;
  return (
    <section className="main-content">
      <div className="main-content-first"></div>
      <div className="main-content-second">
        <div className="main-content-second-left"></div>
        <div className="main-content-second-middle-error">
          <div className="content">
            <div className="content-header"></div>
            <div className="content-facility">
              <div className="content-facility-left"></div>
              <div className="content-facility-details">
                {header}
                <p className="content-faciliy-details-extra">{body}</p>
              </div>
              <div className="content-facility-icon">
                <PriorityHigh className="content-error-icon" />
              </div>
            </div>
            <div className="content-footer"></div>
          </div>
        </div>
        <div className="main-content-second-right"></div>
      </div>
      <div className="main-content-third"></div>
    </section>
  );
};

export default ErrorMessage;

/*
 * Home Action Types
 *
 */

export const LOAD_PATIENT_INFO = 'Home/LOAD_PATIENT_INFO';
export const LOAD_PATIENT_INFO_SUCCESS = 'Home/LOAD_PATIENT_INFO_SUCCESS';
export const LOAD_PATIENT_INFO_ERROR = 'Home/LOAD_PATIENT_INFO_ERROR';

export const LOAD_ENCOUNTER_INFO = 'Home/LOAD_ENCOUNTER_INFO';
export const LOAD_ENCOUNTER_INFO_SUCCESS = 'Home/LOAD_ENCOUNTER_INFO_SUCCESS';
export const LOAD_ENCOUNTER_INFO_ERROR = 'Home/LOAD_ENCOUNTER_INFO_ERROR';

export const LOAD_APPOINTMENT_INFO_SUCCESS = 'Home/LOAD_APPOINTMENT_INFO_SUCCESS';
export const LOAD_APPOINTMENT_INFO_ERROR = 'Home/LOAD_APPOINTMENT_INFO_ERROR';

export const CREATE_OP_APPOINTMENT = 'Home/CREATE_OP_APPOINTMENT';
export const CREATE_OP_APPOINTMENT_SUCCESS = 'Home/CREATE_OP_APPOINTMENT_SUCCESS';

export const LOAD_PRACTITIONER = 'Home/LOAD_PRACTITIONER';
export const LOAD_PRACTITIONER_SUCCESS = 'Home/LOAD_PRACTITIONER_SUCCESS';
export const LOAD_PRACTITIONER_ERROR = 'Home/LOAD_PRACTITIONER_ERROR';

export const CONNECT_INPATIENT = 'Home/CONNECT_INPATIENT';
export const CONNECT_FROM_REQUEST = 'Home/CONNECT_FROM_REQUEST';
export const CONNECT_FROM_APPOINTMENT = 'Home/CONNECT_FROM_APPOINTMENT';

export const REFRESH_TOKEN_SUCCESS = 'App/REFRESH_TOKEN_SUCCESS';

export const LAUNCH_TESTING = 'Home/LAUNCH_TESTING';

export const LOAD_ENDPOINT = 'Home/LOAD_ENDPOINT';
export const LOAD_ENDPOINT_SUCCESS = 'Home/LOAD_ENDPOINT_SUCCESS';
export const LOAD_ENDPOINT_ERROR = 'Home/LOAD_ENDPOINT_ERROR';

export const LOAD_REQUEST = 'Home/LOAD_REQUEST';
export const LOAD_REQUEST_SUCCESS = 'Home/LOAD_REQUEST_SUCCESS';
export const LOAD_REQUEST_ERROR = 'Home/LOAD_REQUEST_ERROR';

export const LOAD_ENCRYPTED_CODE = 'Home/LOAD_ENCRYPTED_CODE';
export const LOAD_ENCRYPTED_CODE_SUCCESS = 'Home/LOAD_ENCRYPTED_CODE_SUCCESS';
export const LOAD_FILTERS = 'Home/LOAD_FILTERS';
export const LOAD_SPECIALITY_LIST_SUCCESS = 'Home/LOAD_SPECIALITY_LIST_SUCCESS';
export const LOAD_FACILITY_LIST_SUCCESS = 'Home/LOAD_FACILITY_LIST_SUCCESS';
export const LOAD_FILTERS_ERROR = 'Home/LOAD_FILTERS_ERROR';

export const DEFER_A_CALL = 'Home/DEFER_A_CALL';
export const DEFER_A_CALL_SUCCESS = 'Home/DEFER_A_CALL_SUCCESS';
export const DEFER_A_CALL_ERROR = 'Home/DEFER_A_CALL_ERROR';
export const REJECT_A_CALL = 'Home/REJECT_A_CALL';
export const REJECT_A_CALL_SUCCESS = 'Home/REJECT_A_CALL_SUCCESS';
export const REJECT_A_CALL_ERROR = 'Home/REJECT_A_CALL_ERROR';

export const LOAD_OUTPATIENT_APPOINTMENT_LIST = 'Home/LOAD_OUTPATIENT_APPOINTMENT_LIST';
export const LOAD_OUTPATIENT_APPOINTMENT_LIST_SUCCESS =
  'Home/LOAD_OUTPATIENT_APPOINTMENT_LIST_SUCCESS';
export const LOAD_OUTPATIENT_APPOINTMENT_LIST_ERROR = 'Home/LOAD_OUTPATIENT_APPOINTMENT_LIST_ERROR';

export const CREATE_OUTPATIENT_APPOINTMENT = 'Home/CREATE_OUTPATIENT_APPOINTMENT';
export const CREATE_OUTPATIENT_APPOINTMENT_SUCCESS = 'Home/CREATE_OUTPATIENT_APPOINTMENT_SUCCESS';
export const CREATE_OUTPATIENT_APPOINTMENT_ERROR = 'Home/CREATE_OUTPATIENT_APPOINTMENT_ERROR';

export const REJECT_APPOINTMENT = 'Home/REJECT_APPOINTMENT';
export const REJECT_APPOINTMENT_SUCCESS = 'Home/REJECT_APPOINTMENT_SUCCESS';
export const REJECT_APPOINTMENT_ERROR = 'Home/REJECT_APPOINTMENT_ERROR';

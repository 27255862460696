import React from 'react';
import { FormControl, InputLabel, makeStyles, Select, MenuItem } from '@material-ui/core';

const HeaderFilter = (props) => {
  const { status, handleFilterChange, facility, facilities, specialty, specialties, type } = props;
  const useFilterStyles = makeStyles((theme) => ({
    wrapper: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'center',
    },
    formControl: {
      margin: theme.spacing(1.5),
      minWidth: 120,
    },
    selectEmpty: {
      marginTop: theme.spacing(2),
    },
  }));

  const filterClasses = useFilterStyles();

  return (
    <div className={filterClasses.wrapper}>
      <FormControl variant="outlined" className={filterClasses.formControl}>
        <InputLabel id="demo-simple-select-outlined-label">Status</InputLabel>
        <Select
          labelId="demo-simple-select-outlined-label"
          id="demo-simple-select-outlined"
          value={status}
          name="Status"
          onChange={handleFilterChange}
          label="Status"
        >
          <MenuItem value="">
            <em>None</em>
          </MenuItem>
          <MenuItem value={'created'}>Request Created</MenuItem>
          <MenuItem value={'waiting'}>Waiting for Session</MenuItem>
          <MenuItem value={'deferred'}>Deferred</MenuItem>
          <MenuItem value={'inprogress'}>Session in Progress</MenuItem>
          <MenuItem value={'completed'}>Completed</MenuItem>
        </Select>
      </FormControl>
      <FormControl variant="outlined" className={filterClasses.formControl}>
        <InputLabel id="demo-simple-select-outlined-label">Facility</InputLabel>
        <Select
          labelId="demo-simple-select-outlined-label"
          id="demo-simple-select-outlined"
          value={facility}
          name="Facility"
          onChange={handleFilterChange}
          label="Facility"
        >
          <MenuItem value="">
            <em>None</em>
          </MenuItem>
          {facilities &&
            facilities.data.map((f) => {
              return (
                <MenuItem key={f.id} value={f.id}>
                  {f.name}
                </MenuItem>
              );
            })}
        </Select>
      </FormControl>
      <FormControl variant="outlined" className={filterClasses.formControl}>
        <InputLabel id="demo-simple-select-outlined-label">Speciality</InputLabel>
        <Select
          labelId="demo-simple-select-outlined-label"
          id="demo-simple-select-outlined"
          value={specialty}
          name="Specialty"
          onChange={handleFilterChange}
          label="Specialty"
        >
          <MenuItem value="">
            <em>None</em>
          </MenuItem>
          {specialties &&
            specialties.data.map((s) => {
              return (
                <MenuItem key={s.id} value={s.id}>
                  {s.name}
                </MenuItem>
              );
            })}
        </Select>
      </FormControl>
      <FormControl variant="outlined" className={filterClasses.formControl}>
        <InputLabel id="demo-simple-select-outlined-label">Type</InputLabel>
        <Select
          labelId="demo-simple-select-outlined-label"
          id="demo-simple-select-outlined"
          value={type}
          name="Type"
          onChange={handleFilterChange}
          label="Type"
        >
          <MenuItem value="">
            <em>None</em>
          </MenuItem>
          <MenuItem value={'TeleKast'}>TeleKast</MenuItem>
          <MenuItem value={'TeleKart'}>TeleKart</MenuItem>
        </Select>
      </FormControl>
    </div>
  );
};

export default HeaderFilter;

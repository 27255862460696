import { Tooltip, Typography } from '@material-ui/core';
import { Help } from '@material-ui/icons';
import React from 'react';

const TooltipView = () => {
  return (
    <>
      <Typography>Need help? Please call us at 833.217.1855</Typography>
      <Typography>or email at support@veeonehealth.com</Typography>
    </>
  );
};

const printHeaderText = (patient, smart, error) => {
  if (!smart || error) {
    return '';
  } else {
    if (smart.patient) {
      if (patient) {
        return patient.name[0].family + ', ' + patient.name[0].given[0];
      } else {
        return '';
      }
    } else {
      return 'Teleconsult Requests';
    }
  }
};

const Header = (props) => {
  const { patient, smart, error } = props;
  return (
    <header className="header">
      <div className="header-left">
        <div className="header-left-logo">
          <img src="logo-veedoc.png" className="header-left-logo-style" />
        </div>
        <div className="header-left-desc">
          <div className="header-left-desc-poweredby">
            <i>
              <span className="header-left-desc-poweredby-text">Powered by&nbsp;</span>
              <span className="header-left-desc-poweredby-text-veeone">VeeOne OS</span>
            </i>
          </div>
        </div>
      </div>

      <div className="header-right">
        <div className="header-right-name">{printHeaderText(patient, smart, error)}</div>
        <div className="header-right-help">
          <Tooltip title={<TooltipView />} placement="left">
            <Help />
          </Tooltip>
        </div>
      </div>
    </header>

    // TODO: this is the location snippet, we don't need in header, but temporarily keep it here
    //         {/* {encounter && encounter.location[0].location.display} */}
    //         {encounter &&
    //           encounter.location[0].location.display +
    //             ' - ' +
    //             encounter.location[0].location.reference}
  );
};

export default Header;

import React from 'react';
import TableRow from '@material-ui/core/TableRow';
import { TableCell } from '@material-ui/core';
import { DateTime } from 'luxon';

function AppointmentRow(props) {
  const { row } = props;

  return (
    <TableRow>
      <TableCell component="th" scope="row">
        {row.patient.mrn}
      </TableCell>
      <TableCell>{DateTime.fromISO(row.startTime).toFormat('LLL dd, yyyy')}</TableCell>
      <TableCell>
        {DateTime.fromISO(row.startTime + 'Z').toFormat('t') +
          ' - ' +
          DateTime.fromISO(row.endTime + 'Z').toFormat('t')}
      </TableCell>
      <TableCell>{row.patient.firstName + ' ' + row.patient.lastName}</TableCell>
      <TableCell>{row.state.replace(/([A-Z])/g, ' $1')}</TableCell>
    </TableRow>
  );
}

export default AppointmentRow;

import Pagination from '@material-ui/lab/Pagination';
import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { SIZE } from '../../constants';

const useStyles = makeStyles((theme) => ({
  root: {
    '& > *': {
      margin: theme.spacing(2),
      display: 'flex',
      flexDirection: 'row-reverse',
    },
  },
}));

export const RequestPagination = (props) => {
  const { page, requests, handlePageChange, filterResult } = props;
  const classes = useStyles();
  const getPages = (requests) => {
    const len = requests.filter((row) => {
      return filterResult(row);
    }).length;
    if (len === 0) return 1;
    return parseInt((len - 1) / SIZE) + 1;
  };
  const pages = requests && getPages(requests);
  return (
    <div className={classes.root}>
      <Pagination count={pages} page={page} color="primary" onChange={handlePageChange} />
    </div>
  );
};



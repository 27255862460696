import React from 'react';
import HeaderFilter from './HeaderFilter';
import WaitingPanel from './WaitingPanel';

const SubHeader = ({
  activeTab,
  status,
  handleFilterChange,
  facility,
  facilities,
  specialty,
  specialties,
  type,
  outpatientAppointments,
  connectFromAppointment,
  code,
  rejectAppointment,
}) => {
  const activeAppointments =
    outpatientAppointments &&
    outpatientAppointments.data.filter((appointment) => {
      return appointment.state === 'SessionRequested';
    });
  switch (activeTab) {
    case 1:
      return (
        <HeaderFilter
          activeTab={activeTab}
          status={status}
          handleFilterChange={handleFilterChange}
          facility={facility}
          facilities={facilities}
          specialty={specialty}
          specialties={specialties}
          type={type}
        />
      );
    case 2:
      return (
        <>
          {/* <AddAppointmentButton
            createOutpatientAppointment={createOutpatientAppointment}
            loadOutpatientAppointments={loadOutpatientAppointments}
          /> */}
          {activeAppointments && activeAppointments.length > 0 && (
            <div>
              <WaitingPanel
                activeAppointments={activeAppointments}
                connectFromAppointment={connectFromAppointment}
                code={code}
                rejectAppointment={rejectAppointment}
              />
            </div>
          )}
        </>
      );
    default:
      return <></>;
  }
};

export default SubHeader;

import React from 'react';
import { CircularProgress } from '@material-ui/core';

export default function Loading() {
  return (
    <section className="main-content-spinner">
      <CircularProgress />
    </section>
  );
}

//this is not being used
export const appointmentTimeSlots = [
  { name: '7:00 AM - 7:15 AM', strValue: '7:00' },
  { name: '7:15 AM - 7:30 AM', strValue: '7:15' },
  { name: '7:30 AM - 7:45 AM', strValue: '7:30' },
  { name: '7:45 AM - 8:00 AM', strValue: '7:45' },
  { name: '8:00 AM - 8:15 AM', strValue: '8:00' },
  { name: '8:15 AM - 8:30 AM', strValue: '8:15' },
  { name: '8:30 AM - 8:45 AM', strValue: '8:30' },
  { name: '8:45 AM - 9:00 AM', strValue: '8:45' },
  { name: '9:00 AM - 9:15 AM', strValue: '9:00' },
  { name: '9:15 AM - 9:30 AM', strValue: '9:15' },
  { name: '9:30 AM - 9:45 AM', strValue: '9:30' },
  { name: '9:45 AM - 10:00 AM', strValue: '9:45' },
  { name: '10:15 AM - 10:30 AM', strValue: '10:15' },
  { name: '10:30 AM - 10:45 AM', strValue: '10:30' },
  { name: '10:45 AM - 11:50 AM', strValue: '9:15' },
  { name: '9:15 AM - 9:30 AM', strValue: '9:15' },
  { name: '9:15 AM - 9:30 AM', strValue: '9:15' },
  { name: '9:15 AM - 9:30 AM', strValue: '9:15' },
  { name: '9:15 AM - 9:30 AM', strValue: '9:15' },
  { name: '9:15 AM - 9:30 AM', strValue: '9:15' },
  { name: '9:15 AM - 9:30 AM', strValue: '9:15' },
  { name: '9:15 AM - 9:30 AM', strValue: '9:15' },
  { name: '6:00 PM - 6:15 PM', strValue: '18:00' },
];

var arr = [],
  i,
  j;
for (i = 7; i < 21; i++) {
  for (j = 0; j < 4; j++) {
    const strValue = (i < 10 ? '0' : '') + i + ':' + (j === 0 ? '00' : 15 * j);
    arr.push(strValue);
  }
}

export const appointmentSlotsSimple = arr.map((slot) => {
  return {
    name: slot,
    strValue: slot,
  };
});

/*
 * AppReducer
 *
 * The reducer takes care of our data. Using actions, we can
 * update our application state. To add a new action,
 * add it to the switch statement in the reducer function
 *
 */
import produce, { enableES5 } from 'immer';
import _ from 'lodash';

import {
  LOAD_SMART_INFO_SUCCESS,
  LOAD_SMART_INFO_FAILURE,
  REFRESH_TOKEN_SUCCESS,
} from './constants';

export const initialState = {
  container: 'App',
};

const appReducer = (state = initialState, action) => {
  enableES5();
  return produce(state, (draft) => {
    switch (action.type) {
      case LOAD_SMART_INFO_SUCCESS:
        draft.smart = _.get(action.payload, 'state.tokenResponse');
        break;
      case LOAD_SMART_INFO_FAILURE:
        draft.error = action.payload;
        break;
      case REFRESH_TOKEN_SUCCESS:
        draft.smart.access_token = action.payload.access_token;
        break;
      default:
        break;
    }
  });
};

export default appReducer;

import FHIR from 'fhirclient';
import jwt from 'jsonwebtoken';

let loaded = false;
let client;

const connect = async () => {
  if (loaded) {
    const token = client.state.tokenResponse.access_token;
    const expiry = jwt.decode(token).exp;
    const now = new Date();
    const isExpired = now.getTime() > expiry * 1000 - 10000;
    if (!isExpired) {
      return client;
    }
    return FHIR.oauth2.ready().then((smart) => {
      loaded = true;
      client = smart;
      return client;
    });
  }
  return FHIR.oauth2.ready().then((smart) => {
    loaded = true;
    client = smart;
    return client;
  });
};

export default connect;

import { Grid, makeStyles, Typography } from '@material-ui/core';
import ConnectButton from '../VMConnectButton';
import RejectButton from '../VMRejectButton';
import React from 'react';
import { DateTime } from 'luxon';

const useStyles = makeStyles({
  waitingPanelWrapper: {
    padding: '20px',
  },
  container: {
    padding: '30px',
    backgroundColor: '#f0f0f0',
  },
  callend: {
    backgroundColor: 'red',
    color: 'white',
    '&:hover': {
      backgroundColor: 'red',
    },
    marginRight: '5px',
  },
  call: {
    backgroundColor: 'lightgreen',
    color: 'white',
    '&:hover': {
      backgroundColor: 'lightgreen',
    },
  },
});

const WaitingPanel = (props) => {
  const classes = useStyles();
  const { activeAppointments, connectFromAppointment, code, rejectAppointment } = props;

  return (
    <>
      {activeAppointments &&
        activeAppointments.map((activeAppointment) => {
          return (
            <Grid key={activeAppointment.id} className={classes.waitingPanelWrapper}>
              <Grid container justifyContent="space-between" className={classes.container}>
                <Grid item>
                  <Typography>Patient is waiting</Typography>
                  <Typography color="textSecondary">
                    Appointment Time :{' '}
                    {DateTime.fromISO(activeAppointment.startTime + 'Z').toLocaleString(
                      DateTime.DATETIME_FULL,
                    )}
                  </Typography>
                  <Typography color="textSecondary">
                    Patient Name : {activeAppointment.patient.firstName}{' '}
                    {activeAppointment.patient.lastName}
                  </Typography>
                  <Typography color="textSecondary">Reason: {activeAppointment.reason}</Typography>
                </Grid>
                <Grid item>
                  <Grid container>
                    <ConnectButton
                      row={activeAppointment}
                      connectACall={connectFromAppointment}
                      code={code}
                      connectType={'appointment'}
                    />
                    <RejectButton
                      row={activeAppointment}
                      rejectACall={rejectAppointment}
                      connectType={'appointment'}
                    />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          );
        })}
    </>
  );
};

export default WaitingPanel;

import React, { useEffect, useState } from 'react';
import Button from '@material-ui/core/Button';
import AddIcon from '@material-ui/icons/Add';
import { makeStyles } from '@material-ui/core/styles';
import {
  TextField,
  Grid,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Typography,
  RadioGroup,
  FormControlLabel,
  Radio,
  Snackbar,
} from '@material-ui/core';
import MuiAlert from '@material-ui/lab/Alert';
import ReactPhoneInput from 'react-phone-input-material-ui';
import 'react-phone-input-material-ui/lib/style.css';
import {
  getDOB,
  getEmail,
  getFirstName,
  getGender,
  getLastName,
  getMobile,
  getMRN,
} from '../../utils/patient';
import { DatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import { DateTime } from 'luxon';
import LuxonUtils from '@date-io/luxon';
import { appointmentSlotsSimple } from '../shared/appointmentTimeSlots';

const useStyles = makeStyles((theme) => ({
  wrapper: {
    padding: theme.spacing(4),
  },
  title: {
    marginBottom: theme.spacing(1),
  },
  button: {
    margin: theme.spacing(2),
  },
  field: {
    margin: '21px 0',
    paddingLeft: '0 !important',
  },
  countryList: {
    ...theme.typography.body1,
  },
  flagButton: {
    height: '10px',
    marginTop: '31px',
  },
  or: {
    paddingTop: '30px',
    paddingLeft: '10px',
  },
  buttonRow: {
    marginTop: '10px',
  },
  textField: {
    marginLeft: theme.spacing(0.5),
    width: '100%',
  },
}));

const useFormStyles = makeStyles((theme) => ({
  formControl: {
    // margin: theme.spacing(1),
    width: '100%',
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
}));

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const AppointmentForm = (props) => {
  const { patient, npi, createOutpatientAppointment, createOutpatientAppointmentRes } = props;
  const classes = useStyles();
  const timezone = DateTime.now().zoneName;
  const formClasses = useFormStyles();
  const disabled = true;
  const [appointmentType, setAppointmentType] = useState('quick');
  const [firstName, setFirstName] = useState(getFirstName(patient));
  const [firstNameError, setFirstNameError] = useState('');
  const [lastName, setLastName] = useState(getLastName(patient));
  const [lastNameError, setLastNameError] = useState('');
  const [mrn, setMrn] = useState(getMRN(patient));
  const [mrnError, setMrnError] = useState('');
  const [phone, setPhone] = useState(getMobile(patient));
  const [phoneError, setPhoneError] = useState('');
  const [email, setEmail] = useState(getEmail(patient));
  const [reason, setReason] = useState('');
  const [reasonError, setReasonError] = useState('');
  const [appointmentDate, setAppointmentDate] = useState(
    DateTime.now().toString().substring(0, 16),
  );
  const [appointmentTime, setAppointmentTime] = useState(appointmentSlotsSimple[0].strValue);
  const [openSuccess, setOpenSuccess] = React.useState(false);
  const [openError, setOpenError] = useState(false);

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpenSuccess(false);
    setOpenError(false);
  };

  const handleAppointmentTimeChange = (event) => {
    setAppointmentTime(event.target.value);
  };

  const handleChangeAppointmentType = (event) => {
    setAppointmentType(event.target.value);
  };

  const startTime = appointmentDate.toString().split('T');
  startTime.splice(1, 1, appointmentTime);

  const handleAppointmentSubmit = () => {
    const appointment = {
      patientFirstName: firstName,
      patientLastName: lastName,
      patientMobileNumber: phone,
      patientEmail: email,
      reason: reason,
      patientTimezone: timezone,
      providerId: npi,
      mrn: mrn,
      startTime: DateTime.fromISO(startTime.join('T')).toUTC().toString(),
      appointmentType: appointmentType,
      dob: getDOB(patient),
      sex: getGender(patient),
    };
    if (mrn === '') {
      setMrnError('MRN is required!');
    } else if (reason === '') {
      setReasonError('Reason is required!');
    } else if (phone === '') {
      setPhoneError('Phone number is required!');
    } else {
      createOutpatientAppointment(appointment);
    }
    //TODO: add snack bar
  };

  useEffect(() => {
    if (createOutpatientAppointmentRes) {
      if (createOutpatientAppointmentRes[1] === 'success') {
        setOpenSuccess(true);
      } else {
        setOpenError(true);
      }
    }
  }, [createOutpatientAppointmentRes && createOutpatientAppointmentRes[0]]);

  return (
    <Grid container className={classes.wrapper}>
      <Grid item xs={8}>
        <Typography color="primary" className={classes.title}>
          Create Outpatient Appointment
        </Typography>
        <Grid container spacing={1}>
          <Grid item xs={6}>
            <RadioGroup
              aria-label="appointmentType"
              name="appointmentType"
              value={appointmentType}
              onChange={handleChangeAppointmentType}
              row
            >
              <FormControlLabel
                value="quick"
                control={<Radio color="primary" />}
                label="Quick Appointment"
              />
              <FormControlLabel
                value="scheduled"
                control={<Radio color="primary" />}
                label="Scheduled Appointment"
              />
            </RadioGroup>
          </Grid>
        </Grid>
        <Grid container spacing={1}>
          <Grid item xs={4}>
            <TextField
              margin="dense"
              id="defer-note"
              label="First Name*"
              error={!!firstNameError}
              type="text"
              fullWidth
              value={firstName}
              disabled={disabled}
              onChange={(event) => {
                setFirstName(event.target.value);
              }}
              onBlur={(event) => {
                if (event.target.value.trim() === '') {
                  setFirstNameError('First Name is required!');
                } else {
                  setFirstNameError('');
                }
              }}
              helperText={firstNameError}
            />
          </Grid>
          <Grid item xs={4}>
            <TextField
              margin="dense"
              id="defer-note"
              label="Last Name*"
              error={!!lastNameError}
              type="text"
              fullWidth
              value={lastName}
              disabled={disabled}
              onChange={(event) => {
                setLastName(event.target.value);
              }}
              onBlur={(event) => {
                if (event.target.value.trim() === '') {
                  setLastNameError('Last Name is required!');
                } else {
                  setLastNameError('');
                }
              }}
              helperText={lastNameError}
            />
          </Grid>
          <Grid item xs={4}>
            <TextField
              margin="dense"
              id="mrn"
              label="MRN*"
              error={!!mrnError}
              type="text"
              fullWidth
              value={mrn}
              disabled={disabled}
              onChange={(event) => {
                setMrn(event.target.value);
              }}
              onBlur={(event) => {
                if (event.target.value.trim() === '') {
                  setMrnError('MRN is required!');
                } else {
                  setMrnError('');
                }
              }}
              helperText={mrnError}
            />
          </Grid>
        </Grid>
        <Grid container spacing={1}>
          <Grid item xs={6}>
            <ReactPhoneInput
              value={phone}
              onChange={(value) => {
                setPhone(value);
              }} // passed function receives the phone value
              component={TextField}
              country="us"
              error={phoneError}
              isValid={!!!phoneError}
              inputClass={classes.field}
              dropdownClass={classes.countryList}
              buttonClass={classes.flagButton}
              onBlur={(event) => {
                if (event.target.value === '') {
                  setPhoneError('Phone number is required!');
                } else {
                  setPhoneError('');
                }
              }}
              helperText={phoneError}
            />
          </Grid>
          {/* <Grid item xs={1}>
          <Typography className={classes.or}>or</Typography>
        </Grid> */}
          <Grid item xs={6}>
            <TextField
              margin="dense"
              id="defer-note"
              label="Email"
              type="text"
              fullWidth
              value={email}
              onChange={(event) => {
                setEmail(event.target.value);
              }}
            />
          </Grid>
        </Grid>
        {/* <Grid container spacing={1}>
        <Grid item xs={12}>
          <FormControl className={formClasses.formControl}>
            <InputLabel id="demo-simple-select-outlined-label">Patient Timezone</InputLabel>
            <Select
              labelId="demo-simple-select-outlined-label"
              id="demo-simple-select-outlined"
              value={timezone}
              name="TimeZone"
              onChange={handleTimezoneChange}
              label="Patient Timezone"
            >
              {timezones.map((zone) => {
                return (
                  <MenuItem key={zone.strValue} value={zone.strValue}>
                    {zone.name}
                  </MenuItem>
                );
              })}
            </Select>
          </FormControl>
        </Grid>
      </Grid> */}
        <Grid container spacing={1}>
          <Grid item xs={12}>
            <TextField
              margin="dense"
              id="defer-note"
              label="Reason*"
              error={!!reasonError}
              type="text"
              fullWidth
              value={reason}
              onChange={(event) => {
                setReason(event.target.value);
              }}
              onBlur={(event) => {
                if (event.target.value === '') {
                  setReasonError('Reason is required!');
                } else {
                  setReasonError('');
                }
              }}
              helperText={reasonError}
            />
          </Grid>
        </Grid>
        <Grid
          container
          spacing={1}
          style={{ display: appointmentType === 'quick' ? 'none' : 'flex', marginTop: '10px' }}
        >
          <Grid item xs={6} style={{ padding: '20px 3px' }}>
            <MuiPickersUtilsProvider utils={LuxonUtils}>
              <FormControl className={formClasses.formControl}>
                <InputLabel id="appointment-datetime" style={{ top: '-38px' }}>
                  Appointment Date
                </InputLabel>
                <DatePicker
                  id="appointment-datetime"
                  value={appointmentDate}
                  onChange={setAppointmentDate}
                />
              </FormControl>
            </MuiPickersUtilsProvider>
          </Grid>
          <Grid item xs={6}>
            <FormControl className={formClasses.formControl}>
              <InputLabel id="demo-simple-select-outlined-label">Appointment Time</InputLabel>
              <Select
                labelId="demo-simple-select-outlined-label"
                id="demo-simple-select-outlined"
                value={appointmentTime}
                name="appointmentTime"
                onChange={handleAppointmentTimeChange}
                label="Appointment Time"
              >
                {appointmentSlotsSimple.map((slot) => {
                  return (
                    <MenuItem key={slot.strValue} value={slot.strValue}>
                      {slot.name}
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
          </Grid>
        </Grid>
        <Grid container spacing={1} justifyContent="flex-end" className={classes.buttonRow}>
          <Button
            variant="outlined"
            startIcon={<AddIcon />}
            onClick={handleAppointmentSubmit}
            color="primary"
          >
            Create
          </Button>
        </Grid>
        <Snackbar
          open={openSuccess}
          autoHideDuration={3000}
          onClose={handleClose}
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
          style={{ marginTop: '80px' }}
        >
          <Alert onClose={handleClose} severity="success">
            The patient has been notified via SMS/Email.
          </Alert>
        </Snackbar>
        <Snackbar
          open={openError}
          autoHideDuration={3000}
          onClose={handleClose}
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
          style={{ marginTop: '80px' }}
        >
          <Alert onClose={handleClose} severity="error">
            {createOutpatientAppointmentRes && createOutpatientAppointmentRes[1]}
          </Alert>
        </Snackbar>
      </Grid>
    </Grid>
  );
};

export default AppointmentForm;

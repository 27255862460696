import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { Router } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';
import { Grid } from 'semantic-ui-react';
import { loadSmartInfoAction } from './actions';
import { getSmartError, getPatient, getSmartInfo, getEncounter } from './selectors';
import history from '../../modules/history';
import Home from '../Home';
import ErrorMessage from '../../components/ErrorMessage';
import Header from '../../components/Header';
import OrganizationView from '../OrganizationView';

function App(props) {
  const { initializeSmartClient } = props;
  useEffect(() => {
    initializeSmartClient();
  }, [initializeSmartClient]);

  const { error, smart, patient, encounter } = props;
  return (
    <Router history={history}>
      <Helmet />
      <Header patient={patient} encounter={encounter} smart={smart} error={error} />
      <div>
        {error ? <ErrorMessage {...error} /> : null}
        {smart ? smart?.patient ? <Home /> : <OrganizationView /> : null}
      </div>
    </Router>
  );
}

function mapStateToProps(state) {
  return {
    error: getSmartError(state),
    smart: getSmartInfo(state),
    patient: getPatient(state),
    encounter: getEncounter(state),
  };
}

function mapDispatchToProps(dispatch) {
  return {
    initializeSmartClient: () => dispatch(loadSmartInfoAction()),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(App);

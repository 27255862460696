import React, { useEffect } from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogTitle from '@material-ui/core/DialogTitle';
import IconButton from '@material-ui/core/IconButton';
import { CallRounded } from '@material-ui/icons';

export default function VMConnectButton({ row, connectACall, code, connectType }) {
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
    connectACall(row);
  };

  const handleClose = () => {
    setOpen(false);
  };

  function createMarkup(code) {
    if (connectType === 'appointment') {
      console.log(`${process.env.REACT_APP_TELE_REDIRECT_APPOINTMENT_URL}?token=${code}`);
      return {
        __html: `<a class="MuiButton-label" style="margin-right: 20px; color: #03ADD0" href="javascript:APPLINK(100,'${process.env.REACT_APP_TELE_REDIRECT_APPOINTMENT_URL}?token=${code}','')">Yes</a>`,
      };
    }
    console.log(`${process.env.REACT_APP_TELE_REDIRECT_REQUEST_URL}?token=${code}`);
    return {
      __html: `<a class="MuiButton-label" style="margin-right: 20px; color: #03ADD0" href="javascript:APPLINK(100,'${process.env.REACT_APP_TELE_REDIRECT_REQUEST_URL}?token=${code}','')">Yes</a>`,
    };
  }

  useEffect(() => {
    let interval = null;
    if (open) {
      interval = setInterval(() => {
        connectACall(row);
      }, 50000);
    } else {
      clearInterval(interval);
    }
    return () => clearInterval(interval);
  }, [open, connectACall, row]);

  useEffect(() => {
    setOpen(false);
  }, [row.orderStatus]);

  return (
    <>
      <IconButton
        onClick={handleClickOpen}
        disabled={
          row.endpointStatus === 'offline' || row.orderStatus === 'inprogress' ? true : false
        }
      >
        <CallRounded
          color={
            row.endpointStatus === 'offline' || row.orderStatus === 'inprogress'
              ? 'inherit'
              : 'secondary'
          }
        />
      </IconButton>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {'Are you ready to connect with patient?'}
        </DialogTitle>
        {/* <DialogContent>
          <DialogContentText id="alert-dialog-description">
            When you reject this call, it will go to the next available specialist.
          </DialogContentText>
        </DialogContent> */}
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            No
          </Button>
          {/* <div dangerouslySetInnerHTML={createMarkup(code)} /> */}
          <div dangerouslySetInnerHTML={createMarkup(code)} />
          {/* <Button onClick={connect} color="primary" autoFocus>
            Yes
          </Button> */}
        </DialogActions>
      </Dialog>
    </>
  );
}

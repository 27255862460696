import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import VMTable from '../../components/VMTable';

import { getNpi } from '../Home/selectors';
import {
  CONNECT_FROM_APPOINTMENT,
  CONNECT_FROM_REQUEST,
  DEFER_A_CALL,
  LOAD_FILTERS,
  LOAD_OUTPATIENT_APPOINTMENT_LIST,
  LOAD_PRACTITIONER,
  LOAD_REQUEST,
  REJECT_APPOINTMENT,
  REJECT_A_CALL,
} from '../Home/constants';

const OrganizationView = ({
  error,
  patientId,
  loadRequest,
  npi,
  requests,
  loadFilters,
  specialties,
  facilities,
  loadOutpatientAppointments,
  outpatientAppointments,
  connectFromRequest,
  loadPractitioner,
  rejectACall,
  rejectRes,
  deferACall,
  deferRes,
  connectFromAppointment,
  rejectAppointment,
  code,
}) => {
  useEffect(() => {
    if (!error) {
      loadFilters();
      loadRequest();
      loadPractitioner();
    }
  }, [error, loadFilters, loadRequest, loadPractitioner]);

  return (
    <React.Fragment>
      <div>
        {!patientId && npi && (
          <VMTable
            requests={requests}
            loadRequest={loadRequest}
            connect={connectFromRequest}
            connectFromAppointment={connectFromAppointment}
            npi={npi}
            specialties={specialties}
            facilities={facilities}
            deferACall={deferACall}
            rejectACall={rejectACall}
            rejectRes={rejectRes}
            deferRes={deferRes}
            code={code}
            loadOutpatientAppointments={loadOutpatientAppointments}
            outpatientAppointments={outpatientAppointments}
            rejectAppointment={rejectAppointment}
          ></VMTable>
        )}
      </div>
    </React.Fragment>
  );
};

const mapStateToProps = (state) => {
  return {
    npi: getNpi(state),
    requests: state.home.requests,
    specialties: state.home.specialties,
    facilities: state.home.facilities,
    outpatientAppointments: state.home.outpaitentAppointments,
    rejectRes: state.home.rejectCallRes,
    deferRes: state.home.deferCallRes,
    code: state.home.encryptedCode,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    loadRequest: () => {
      dispatch({ type: LOAD_REQUEST });
    },
    loadFilters: () => {
      dispatch({ type: LOAD_FILTERS });
    },
    loadPractitioner: () => {
      dispatch({ type: LOAD_PRACTITIONER });
    },
    loadOutpatientAppointments: () => {
      dispatch({ type: LOAD_OUTPATIENT_APPOINTMENT_LIST });
    },
    connectFromRequest: (row) => {
      dispatch({ type: CONNECT_FROM_REQUEST, row });
    },
    rejectACall: (row) => {
      dispatch({ type: REJECT_A_CALL, row });
    },
    deferACall: (row, value) => {
      dispatch({ type: DEFER_A_CALL, row, value });
    },
    connectFromAppointment: (row) => {
      dispatch({ type: CONNECT_FROM_APPOINTMENT, row });
    },
    rejectAppointment: (row) => {
      dispatch({ type: REJECT_APPOINTMENT, row });
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(OrganizationView);

/**
 * sagas
 */

import { put, all, call, takeEvery } from 'redux-saga/effects';
import { LOAD_SMART_INFO } from './constants';
import { addSmartInformationAction, addSmartInformationErrorAction } from './actions';
import { parseJwt } from '../../utils/jwt';

import connect from '../../services/FhirClient';

export function* loadSmartInfo() {
  try {
    const client = yield call(connect);
    if (client.state.tokenResponse.id_token) {
      const id_token = client.state.tokenResponse.id_token;
      const decodedIdToken = parseJwt(id_token);
      client.state.tokenResponse.user = decodedIdToken.fhirUser.split('Practitioner/')[1];
      client.state.tokenResponse.fhirUserName = decodedIdToken.name;
    }
    yield put(addSmartInformationAction(client));
  } catch (e) {
    yield put(addSmartInformationErrorAction(e));
  }
}

export default function* root() {
  yield all([takeEvery(LOAD_SMART_INFO, loadSmartInfo)]);
}

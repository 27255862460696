import { PhonelinkOffOutlined } from '@material-ui/icons';
import React from 'react';

export default function NoDevice({ encounter }) {
  return (
    <section className="main-content">
      <div className="main-content-first"></div>
      <div className="main-content-second">
        <div className="main-content-second-left"></div>
        <div className="main-content-second-middle-error">
          <div className="content">
            <div className="content-header"></div>
            <div className="content-facility-no-device">
              <div className="content-facility-details-no-device">
                <div>{encounter && encounter.location[0].location.display}</div>
                <div className="content-facility-detail-no-device-icon">
                  <PhonelinkOffOutlined fontSize="large" color="action" />
                </div>
                <div className="content-faciliy-details-extra-no-device">No device found.</div>
                <div className="content-faciliy-details-extra-no-device">
                  This location may not have VeeKast installed.
                </div>
              </div>
            </div>
            <div className="content-footer"></div>
          </div>
        </div>
        <div className="main-content-second-right"></div>
      </div>
      <div className="main-content-third"></div>
    </section>
  );
}

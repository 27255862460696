import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import DirectConnection from '../../components/DirectConnection';

import {
  LOAD_PATIENT_INFO,
  CREATE_OP_APPOINTMENT,
  CONNECT_INPATIENT,
  LAUNCH_TESTING,
  LOAD_ENDPOINT,
  LOAD_REQUEST,
  CONNECT_FROM_REQUEST,
  DEFER_A_CALL,
  REJECT_A_CALL,
  LOAD_FILTERS,
  LOAD_ENCRYPTED_CODE,
  LOAD_OUTPATIENT_APPOINTMENT_LIST,
  CREATE_OUTPATIENT_APPOINTMENT,
  CONNECT_FROM_APPOINTMENT,
  REJECT_APPOINTMENT,
  LOAD_ENCOUNTER_INFO,
  LOAD_PRACTITIONER,
} from './constants';
import {
  getReady,
  getEncounter,
  getPatient,
  getPatientId,
  getEndpoint,
  getNpi,
  getPractitioner,
} from './selectors';

import AppointmentForm from '../../components/OutPatient/AppointmentForm';
import Loading from '../../components/Loading/Loading';
import ErrorMessage from '../../components/ErrorMessage';

function Home(props) {
  const {
    error,
    patient,
    loadPatient,
    loadEncounter,
    loadEndpoint,
    loadPractitioner,
    practitioner,
    ready,
    encounter,
    endpoint,
    npi,
    code,
    loadEncryptedCode,
    createOutpatientAppointment,
    createOutpatientAppointmentRes,
  } = props;
  useEffect(() => {
    if (!error && !patient) {
      loadPatient();
      loadEncounter();
      loadEndpoint();
      loadPractitioner();
    }
  }, [loadPatient, loadEncounter, loadEndpoint, loadPractitioner, error, patient]);

  if (!patient || !practitioner || !encounter) {
    return <Loading />;
  }

  return (
    <React.Fragment>
      {patient && encounter && encounter.type[0].text === 'Outpatient' && (
        <AppointmentForm
          patient={patient}
          npi={npi}
          createOutpatientAppointment={createOutpatientAppointment}
          createOutpatientAppointmentRes={createOutpatientAppointmentRes}
        />
      )}
      {ready && patient && practitioner && encounter && encounter.type[0].text === 'Inpatient' && (
        <DirectConnection
          endpoint={endpoint}
          loadEndpoint={loadEndpoint}
          code={code}
          loadEncryptedCode={loadEncryptedCode}
          encounter={encounter}
        />
      )}
      {encounter &&
        encounter.type[0].text !== 'Outpatient' &&
        encounter.type[0].text !== 'Inpatient' && (
          <ErrorMessage
            header={`Encounter type is not supported`}
            body={'Please contact VeeOne support to enable this encounter'}
          />
        )}
    </React.Fragment>
  );
}

const mapStateToProps = (state) => {
  return {
    ready: getReady(state),
    patientId: getPatientId(state),
    patient: getPatient(state),
    practitioner: getPractitioner(state),
    encounter: getEncounter(state),
    endpoint: getEndpoint(state),
    npi: getNpi(state),
    opappointment: state.home.opappointment,
    requests: state.home.requests,
    specialities: state.home.specialities,
    facilities: state.home.facilities,
    code: state.home.encryptedCode,
    rejectRes: state.home.rejectCallRes,
    deferRes: state.home.deferCallRes,
    outpatientAppointments: state.home.outpaitentAppointments,
    createOutpatientAppointmentRes: state.home.createOutpatientAppointmentRes,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    loadPatient: () => {
      dispatch({ type: LOAD_PATIENT_INFO });
    },
    loadPractitioner: () => {
      dispatch({ type: LOAD_PRACTITIONER });
    },
    loadEncounter: () => {
      dispatch({ type: LOAD_ENCOUNTER_INFO });
    },
    loadEndpoint: () => {
      dispatch({ type: LOAD_ENDPOINT });
    },
    createOPAppointment: () => {
      alert('appointment has been created!');
      dispatch({ type: CREATE_OP_APPOINTMENT });
    },
    connectInpatient: () => {
      dispatch({ type: CONNECT_INPATIENT });
    },
    connectFromRequest: (row) => {
      dispatch({ type: CONNECT_FROM_REQUEST, row });
    },
    connectFromAppointment: (row) => {
      dispatch({ type: CONNECT_FROM_APPOINTMENT, row });
    },
    launchTesting: () => {
      dispatch({ type: LAUNCH_TESTING });
    },
    loadRequest: () => {
      dispatch({ type: LOAD_REQUEST });
    },
    deferACall: (row, value) => {
      dispatch({ type: DEFER_A_CALL, row, value });
    },
    rejectACall: (row) => {
      dispatch({ type: REJECT_A_CALL, row });
    },
    loadFilters: () => {
      dispatch({ type: LOAD_FILTERS });
    },
    loadEncryptedCode: () => {
      dispatch({ type: LOAD_ENCRYPTED_CODE });
    },
    loadOutpatientAppointments: () => {
      dispatch({ type: LOAD_OUTPATIENT_APPOINTMENT_LIST });
    },
    createOutpatientAppointment: (appointment) => {
      dispatch({ type: CREATE_OUTPATIENT_APPOINTMENT, appointment });
    },
    rejectAppointment: (row) => {
      dispatch({ type: REJECT_APPOINTMENT, row });
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Home);

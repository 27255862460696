import { Adjust } from '@material-ui/icons';
import React, { useEffect } from 'react';
import { capitalizeFirstLetter } from '../../utils/general';
import NoDevice from './NoDevice';

export default function DirectConnection({
  endpoint,
  loadEndpoint,
  code,
  loadEncryptedCode,
  encounter,
}) {
  function renderEndpointStatus(status) {
    switch (status) {
      case 'online':
        return <Adjust fontSize="small" color="secondary" />;
      case 'busy':
        return <Adjust fontSize="small" color="error" />;
      case 'offline':
        return <Adjust fontSize="small" color="action" />;
      default:
        return <></>;
    }
  }

  function createMarkup(code) {
    console.log(`${process.env.REACT_APP_TELE_REDIRECT_URL}?token=${code}`);
    const html =
      endpoint?.status === 'online' && code
        ? `<a type="button" class="content-connect-btn" href="javascript:APPLINK(100,'${process.env.REACT_APP_TELE_REDIRECT_URL}?token=${code}','')">Connect</a>`
        : `<a type="button" class="content-connect-btn-disabled" href="#">Connect</a>`;
    return { __html: html };
  }

  useEffect(() => {
    loadEndpoint();
    loadEncryptedCode();
    const interval = setInterval(() => {
      loadEndpoint();
      loadEncryptedCode();
    }, 10000);
    return () => clearInterval(interval);
  }, [loadEndpoint, loadEncryptedCode]);

  if (!endpoint) {
    return <></>;
  }

  if (endpoint.error) {
    return <NoDevice encounter={encounter} />;
  }

  return (
    <section className="main-content">
      <div className="main-content-first"></div>
      <div className="main-content-second">
        <div className="main-content-second-left"></div>
        <div className="main-content-second-middle">
          <div className="content">
            <div className="content-header"></div>
            <div className="content-facility">
              <div className="content-facility-left"></div>
              <div className="content-facility-details">
                {encounter && encounter.location[0].location.display}
                {/* Banner Wyoming Medical Center */}
                <p className="content-faciliy-details-extra">
                  {endpoint?.serialNumber}({endpoint?.name})
                </p>
              </div>
              {/*<div className="content-facility-icon">
                 <span className="material-icons-outlined content-facility-icon-style">devices</span> 
                <Computer />
              </div>*/}
            </div>

            <div className="content-spacer-2"></div>
            <div className="content-status">
              <div className="content-status-left"></div>
              <div className="content-status-details">
                Device Status
                <div className="status-text-icon">
                  <div className="content-status-details-extra">
                    {endpoint?.status && capitalizeFirstLetter(endpoint?.status)}
                  </div>{' '}
                  <div className="content-status-details-extra-icon">
                    {renderEndpointStatus(endpoint?.status)}
                  </div>
                </div>
              </div>
              {/*<div className="content-facility-icon">
                <span className="material-icons-outlined content-facility-icon-style">
                  connected_tv
                </span> 
                {renderEndpointStatus(endpoint?.status)}
              </div>*/}
            </div>
            <div className="content-spacer-3"></div>
            {/* <div className="content-connect"> */}
            <div className="content-connect" dangerouslySetInnerHTML={createMarkup(code)} />
            {/* <a type="button" className="content-connect-btn">
                Connect
              </a> */}
            {/* </div> */}
            <div className="content-footer"></div>
          </div>
        </div>
        <div className="main-content-second-right"></div>
      </div>
      <div className="main-content-third"></div>
    </section>

    // <React.Fragment>
    //   <div>
    //     <Grid columns={3}>
    //       <Grid.Row>
    //         <Grid.Column>
    //           <Text style={label}>Device Name: {endpoint?.serialNumber}</Text>
    //           {/* {endpoint && <Text style={label}>{endpoint.serialNumber}</Text>} */}
    //         </Grid.Column>
    //         <Grid.Column textAlign="center">
    //           <Button icon onClick={loadEndpoint} style={{ background: 'transparent' }}>
    //             <Icon name="refresh" />
    //           </Button>
    //         </Grid.Column>
    //       </Grid.Row>
    //       <Grid.Row style={{ marginTop: '-12px' }}>
    //         <Grid.Column>
    //           <Text style={label}>Device Status</Text>
    //         </Grid.Column>
    //         <Grid.Column textAlign="center">
    //           {endpoint && renderEndpointStatus(endpoint.status)}
    //         </Grid.Column>
    //       </Grid.Row>
    //       <Grid.Row>
    //         <Grid.Column>
    //           <Text style={label}>Connect to start session</Text>
    //         </Grid.Column>
    //         <Grid.Column textAlign="center">
    //           <div dangerouslySetInnerHTML={createMarkup(code)} />
    //         </Grid.Column>
    //       </Grid.Row>
    //     </Grid>
    //   </div>
    // </React.Fragment>
  );
}

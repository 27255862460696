import React, { useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import TableContainer from '@material-ui/core/TableContainer';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import { RequestTable } from './RequestTable';
import HeaderTab from './HeaderTab';
import SubHeader from './SubHeader';
import { OutpatientAppointmentTable } from './OutpatientAppointmentTable';
import { SIZE } from '../../constants';
const useTableStyles = makeStyles({
  table: {
    maxWidth: '100%',
  },
});
const useStyles = makeStyles((theme) => ({
  wrapper: {
    padding: theme.spacing(4),
  },
  title: {
    marginBottom: '10px',
  },
}));

export default function CollapsibleTable(props) {
  const {
    requests,
    loadRequest,
    connect,
    npi,
    specialties,
    facilities,
    deferACall,
    rejectACall,
    rejectRes,
    deferRes,
    code,
    loadOutpatientAppointments,
    outpatientAppointments,
    createOutpatientAppointment,
    connectFromAppointment,
    rejectAppointment,
  } = props;

  const [activeTab, setActiveTab] = React.useState(0);
  const [status, setStatus] = React.useState('');
  const [facility, setFacility] = React.useState('');
  const [specialty, setSpecialty] = React.useState('');
  const [type, setType] = React.useState('');
  const [page, setPage] = React.useState(1);

  const handleTabChange = (event, newValue) => {
    setActiveTab(newValue);
    setPage(1);
    if (newValue === 0) {
      setStatus('');
      setFacility('');
      setSpecialty('');
      setType('');
    }
  };
  const handlePageChange = (event, value) => {
    setPage(value);
  };

  const handleFilterChange = (event) => {
    const func = `set${event.target.name}('${event.target.value}')`;
    // eslint-disable-next-line
    eval(func);
    setPage(1);
  };
  const tableClasses = useTableStyles();
  const classes = useStyles();

  const renderTable = () => {
    switch (activeTab) {
      case 0:
      case 1:
        return (
          <RequestTable
            status={status}
            activeTab={activeTab}
            requests={requests}
            facility={facility}
            specialty={specialty}
            type={type}
            size={SIZE}
            page={page}
            loadRequest={loadRequest}
            connect={connect}
            npi={npi}
            specialties={specialties}
            facilities={facilities}
            deferACall={deferACall}
            rejectACall={rejectACall}
            rejectRes={rejectRes}
            deferRes={deferRes}
            code={code}
            handleTabChange={handleTabChange}
            handleFilterChange={handleFilterChange}
            handlePageChange={handlePageChange}
            loadOutpatientAppointments={loadOutpatientAppointments}
            outpatientAppointments={outpatientAppointments}
            createOutpatientAppointment={createOutpatientAppointment}
            connectFromAppointment={connectFromAppointment}
            rejectAppointment={rejectAppointment}
          />
        );
      default:
        return (
          <OutpatientAppointmentTable
            handleTabChange={handleTabChange}
            loadRequest={loadRequest}
            activeTab={activeTab}
            status={status}
            handleFilterChange={handleFilterChange}
            facility={facility}
            facilities={facilities}
            specialty={specialty}
            specialties={specialties}
            type={type}
            loadOutpatientAppointments={loadOutpatientAppointments}
            createOutpatientAppointment={createOutpatientAppointment}
            outpatientAppointments={outpatientAppointments}
            connectFromAppointment={connectFromAppointment}
            code={code}
            rejectAppointment={rejectAppointment}
          />
        );
    }
  };

  // useEffect(() => {
  //   loadRequest();
  // }, [rejectRes, deferRes]);

  useEffect(() => {
    loadRequest();
    loadOutpatientAppointments();
    const interval = setInterval(() => {
      loadRequest();
      loadOutpatientAppointments();
    }, 10000);
    return () => clearInterval(interval);
  }, [rejectRes, deferRes, loadRequest, loadOutpatientAppointments]);

  return (
    <Grid container className={classes.wrapper}>
      <TableContainer component={Paper} className={tableClasses.table}>
        <HeaderTab
          activeTab={activeTab}
          handleTabChange={handleTabChange}
          loadRequest={loadRequest}
        />
        <SubHeader
          activeTab={activeTab}
          status={status}
          handleFilterChange={handleFilterChange}
          facility={facility}
          facilities={facilities}
          specialty={specialty}
          specialties={specialties}
          type={type}
          loadOutpatientAppointments={loadOutpatientAppointments}
          createOutpatientAppointment={createOutpatientAppointment}
          outpatientAppointments={outpatientAppointments}
          connectFromAppointment={connectFromAppointment}
          code={code}
          rejectAppointment={rejectAppointment}
        />
        {renderTable()}
      </TableContainer>
    </Grid>
  );
}

export const getFirstName = (patient) => {
  const official = patient.name.filter((n) => {
    return n.use === 'official';
  });
  if (official) {
    return official[0].given[0];
  }
  return '';
};

export const getLastName = (patient) => {
  const official = patient.name.filter((n) => {
    return n.use === 'official';
  });
  if (official) {
    return official[0].family;
  }
  return '';
};

export const getMRN = (patient) => {
  const mrns = patient.identifier.filter((mrn) => {
    return mrn.type.text === 'MRN';
  });
  if (mrns) {
    return mrns[0].value;
  }
  return '';
};

export const getMobile = (patient) => {
  if (!patient.telecom) {
    return '';
  }
  const mobiles = patient.telecom.filter((tele) => {
    return tele.use === 'mobile' && tele.rank === 1;
  });
  if (mobiles.length > 0) {
    return `1${mobiles[0].value}`;
  }
  return '';
};

export const getEmail = (patient) => {
  if (!patient.telecom) {
    return '';
  }
  const emails = patient.telecom.filter((tele) => {
    return tele.system === 'email' && tele.rank === 1;
  });
  if (emails.length > 0) {
    return emails[0].value;
  }
  return '';
};

export const getGender = (patient) => {
  return patient.gender;
};

export const getDOB = (patient) => {
  return patient.birthDate;
};
